import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { ApiCallInterface, AssessmentItemInterface, AttemptedAssessmentInterface } from './IAssessment'
import StorageProvider from "../../../framework/src/StorageProvider";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React, { ChangeEvent } from "react";
import * as Yup from "yup";

interface Choice {
  id:string;
  content: string;
  is_correct: boolean;
}

interface MediaUrls{
  id:string;
  url:string;
}

interface Question {
  id:string;
  quiz_id:string;
  content: string;
  description: string;
  question_type: string;
  choices_attributes: Choice[];
  media_files_urls:MediaUrls[];
  media_files:File[];
}

interface Quiz {
  title: string;
  passing_grade: string;
  course_id: number;
  questions_attributes: Question[];
}

export const QuestionSchema = Yup.object({
  content: Yup.string().trim().min(1, 'This field is required'),
  description: Yup.string().nullable(),
  question_type: Yup.string().nullable().default(""),
  choices_attributes: Yup.mixed().default([])
});
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  courseId:number;
  onSaveAndClose:(values: any) => void; 
  onAddNewLesson:(values: any) => void; 
  handleOpenAlertModalFinalTest :(values: any) => void; 
  quiz_id:string;
  testType:string;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  assessmentList: AssessmentItemInterface[],
  assessmenttestList: AssessmentItemInterface[],
  isError: boolean;
  assessmentCategories: AssessmentItemInterface[];
  isSuccess: boolean;
  selectedCategory: string | undefined;
  selectedId: string | number | undefined;
  tabNum: number
  attemptedAssessment: AttemptedAssessmentInterface[];
  isLoading: boolean;
  darkTheme:boolean;
  quizTabs:number;
  quizTitle:string;
  passingGrade:string;
  courseId:number;
  allQuestions:Question[];
  selectedQuestionType:string;
  fileData:{fileName:string,filesize:number,fileType:string};
  uploadCSV:Blob|null;
  expandedQuestionIndex: number | null;
  isEdittingQuestion: number | null;
  successModalOpen:boolean;
  showMedia:boolean;
  isRequired:boolean;
  randomQuestion:string;
  question_count:number;
  randomRequired:boolean;
  passRequired:boolean;
  reOrderIds:number[];
  isSaveAndCloseOpen:boolean;
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAssessmentCategories: string = "";
  apiGetAssessmentByCategory: string = "";
  apiAttemptedAssessment: string = "";
  addQuizPostAPICallId:string="";
  updateQuizPostAPICallId:string="";
  getQuizPostAPICallId:string="";
  addImportFileApiCallId:string="";
  quillRef:any="";
  fileRef:any="";
  questionFormikRef: any;
  deleteApiCallId:string="";
  deleteImageApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      assessmentList: [],
      assessmenttestList: [],
      isError: false,
      isLoading: false,
      isSuccess: false,
      assessmentCategories: [],
      selectedCategory: '',
      selectedId: '0',
      tabNum: 1,
      attemptedAssessment: [],
      darkTheme:false,
      quizTabs: 2,
      quizTitle:"",
      passingGrade:"",
      courseId: this.props.courseId,
      allQuestions:[],
      selectedQuestionType:"Question Type",
      fileData:{
        fileName:"",
        filesize:0,
        fileType:""
      },
      uploadCSV:null,
      expandedQuestionIndex: null,
      isEdittingQuestion: null,
      successModalOpen:false,
      showMedia:false,
      isRequired:false,
      randomQuestion:"",
      question_count:0,
      randomRequired:false,
      passRequired:false,
      reOrderIds:[],
      isSaveAndCloseOpen:false,
    }
    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
    ];

    this.quillRef = React.createRef();
    this.fileRef = React.createRef();
    this.questionFormikRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


// Customizable Area Start
  async componentDidMount() {
    this.getAssessmentCategoriesApi()

    this.getAttemptedAssessmentApi()
    this.checkDarkMode()
    this.getQuiz(this.props.quiz_id)
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.assessmentCategories !== this.state.assessmentCategories) {
      this.setState({
        selectedId: `${this.state?.assessmentCategories![0]?.id}`
      })
    }

  }

  apiAssessmentCall = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = data;
    let token = await StorageProvider.get("USER_TOKEN")
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiStatement(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleApiStatement(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (responseJson && responseJson.data) {
      if (apiRequestCallId === this.apiGetAssessmentByCategory) {
        this.getAssessmentByCategorySuccesscallBack(responseJson.data);
      }
      else if (apiRequestCallId === this.apiGetAssessmentCategories) {
        this.getCatSuccesscallBack(responseJson.data,)
      }
      else if (apiRequestCallId === this.apiAttemptedAssessment) {
        this.getAttemptedAssessmentApiSuccesscallBack(responseJson.data)
      }
      else if (apiRequestCallId === this.addQuizPostAPICallId) {
        this.setState({isLoading:false})
        this.getAttemptedAssessmentApiSuccesscallBack(responseJson.data)
        let values = {
          title: responseJson.data.attributes.title, content: '', optional_text:responseJson.data.id,
        }
        if (typeof this.pendingCallback === "function") {
          this.pendingCallback(values);
          this.pendingCallback = null; 
        }else{
          const storeAndNavigate = async () => {
            await setStorageData("courseId", this.props.courseId);
            await setStorageData("previewMode", "true");
            if(this.state.isSaveAndCloseOpen){
              this.props.navigation.navigate("Dashboard");
              this.setState({isSaveAndCloseOpen:false})
            }else{
              this.props.navigation.navigate("Projectnotes",{id:this.props.courseId});
            }
          };
          storeAndNavigate();
        }
        this.handleQuizSection()
      }
      else if (apiRequestCallId === this.updateQuizPostAPICallId) {
        this.setState({isLoading:false})

        let values = {
          title: "quiz", content: '', optional_text:this.props.quiz_id,
        }
        if (typeof this.pendingCallback === "function") {
          this.pendingCallback(values);
          this.pendingCallback = null;
        }else{
          this.props.navigation.navigate("Projectnotes")
        }
      }
      else if (apiRequestCallId === this.getQuizPostAPICallId) {
        const { attributes } = responseJson.data;
        this.setState({
          isLoading:false,
          allQuestions: this.transformQuestions(this.props.testType === "quiz" ? attributes.questions.data : attributes.final_test_questions.data),
            quizTabs: 0,
            quizTitle:attributes.title,
            passingGrade: `${attributes.passing_grade}%`,
            passRequired: !!attributes.passing_grade,
            randomRequired: !!attributes.random_question,
            randomQuestion: attributes.random_question,
            isRequired: attributes.is_required,
            question_count: attributes.question_count
        });
      }      
    }  else if (errorReponse) {
      this.setState({
        isLoading: false,
        
        isSuccess: false,
        isError: true,
      })
    } else if(responseJson && responseJson.message || responseJson.quiz){
        if (apiRequestCallId === this.deleteApiCallId) {
          this.getQuiz(this.props.quiz_id);
          this.setState({
            isLoading:false,
            expandedQuestionIndex: null, fileData: {
              fileName: '',
              filesize: 0,
              fileType: '',
            }
          })
        }
        if (apiRequestCallId === this.deleteImageApiCallId) {
          this.getQuiz(this.props.quiz_id);
        }
        else if (apiRequestCallId === this.getQuizPostAPICallId) {
          this.setState({allQuestions:[], isLoading:false})
        }
        else if (apiRequestCallId === this.addImportFileApiCallId) {
          const { testType } = this.props;
          
          const questionKey = testType === "quiz" ? "question_attributes" : "final_test_question_attributes";
          const choiceKey = testType === "quiz" ? "choices_attributes" : "final_test_choices_attributes";
          
          const allQuestions: Question[] = responseJson[questionKey].flatMap((question: any) => 
            ([
              {
                media_files_urls: [],
                id: question.id || "", 
                content: question.content || "",
                description: question.description || "",
                question_type: question.question_type || "",
                choices_attributes: (question[choiceKey] || []).map((choice: any) => ({
                  id: choice.id || "",
                  content: choice.content || "",
                  is_correct: choice.is_correct || false,
                })), 
              }
            ])
          );
        
          this.setState(prevState => ({
            allQuestions: [...prevState.allQuestions, ...allQuestions], 
            quizTabs: 0,
            expandedQuestionIndex: null,
          }));
        }    
      } else if (responseJson && responseJson?.errors) {
        this.setState({ isLoading: false });
      }   
  }

  transformQuestions(questions: any | any[]): Question[] {
    const questionArray = Array.isArray(questions) ? questions : [questions];
    return questionArray.map((question: any) => ({
      quiz_id: question.attributes.quiz_id,
      media_files_urls:question.attributes.media_files_urls?.map((file:any) => ({
        id:file.id,
        url:file.url
      })),
      media_files:[],
      id:question.attributes.id,
      content: question.attributes.content,
      description: question.attributes.description || "",
      question_type: question.attributes.question_type || "",
      choices_attributes: question.attributes[
        this.props.testType === "quiz" ? "choices" : "final_test_choices"
      ].data.map((choice: any) => ({
        id: choice.attributes.id,
        content: choice.attributes.content,
        is_correct: choice.attributes.is_correct,
      })),
    }));
  }

  getAssessmentCategoriesApi = async () => {
    this.apiGetAssessmentCategories = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAssessementTestCategoriesUrl,
    });
  };
  getAssessmentByCategoryApi = async () => {
    this.apiGetAssessmentByCategory = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAllAssessementTestUrl,
    });
  };
  getAttemptedAssessmentApi = async () => {
    this.apiAttemptedAssessment = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAttemptedAssessmentsUrl,
    });
  };

  getAssessmentByCategorySuccesscallBack =  (data: AssessmentItemInterface[]) => {
    let filterData = data.filter((item: AssessmentItemInterface) => {
      {
        return item.attributes?.assessment_type === this.state.selectedCategory
      }
    })
    this.setState({
      isLoading: false,
      isError: false,
      isSuccess: true,
      assessmentList: data,
      assessmenttestList: filterData
    })
  };

  getCatSuccesscallBack =  (data: AssessmentItemInterface[]) => {
    this.setState({
      assessmentCategories: data,
      selectedCategory: data[0].attributes?.name,
      selectedId: data[0].id
    },
      () => {
        this.getAssessmentByCategoryApi()
      }
    )
  };


  getAttemptedAssessmentApiSuccesscallBack =  (data: AttemptedAssessmentInterface[]) => {
    this.setState({
      attemptedAssessment: data
    })
  };

  
  apiCall = async (data: { [key: string]: any }) => {
    const { method2, endPoint2, body2, type2, contentType2 } = data;
    let apiBody = body2;
    if (type2 === '') {
      apiBody = JSON.stringify(body2);
    }
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": contentType2,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint2
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.baseURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method2
    );
    body2 &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };


  handleDeleteQuiz =  async () =>{
    this.setState({
      expandedQuestionIndex: null, allQuestions: [], fileData: {
        fileName: '',
        filesize: 0,
        fileType: '',
      }
    })
  }


  handleDeleteQuestion =  async (quiz_id:string, id:string) =>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postQuizURL + "/" + `${this.props.testType}`+ "/" + `${quiz_id}` + "/delete_question/" + `${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteQuestion = (index: number, quiz_id:string, id:string, event:any) => {

    if (event) {
      event.stopPropagation();
    }

    if(id){
      this.handleDeleteQuestion(quiz_id,id)
    }else{
      this.setState(prevState => ({
        allQuestions: prevState.allQuestions.filter((_, i) => i !== index)
      })); 
    }
 
  }

  stopPropagration = (event:any) =>{
    if (event) {
      event.stopPropagation();
    }
  }

  getQuiz = async (id:string) => {
    this.setState({isLoading:true})
    const header = {
      token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuizPostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postQuizURL}/${this.props.testType}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssessementMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  sendFile=async()=>{
     
    const token = await getStorageData("authToken"); 

    let data = new FormData();
    data.append("file",this.state.uploadCSV as Blob);
    data.append("course_id", this.props.courseId.toString());

    this.addImportFileApiCallId = await this.apiCall({
      method2: configJSON.postMethod,
      endPoint2: `${configJSON.postQuizURL}/${this.props.testType}${this.props.testType === "final_tests" ? `/${this.props.courseId}` : ""}/import_csv`,
      body2: data,
      headers2: { 
        token 
      },
    });
  }
  onSelected(item: AssessmentItemInterface) {
    this.props.navigation.navigate('AssessmentDetail', { id: item.id })
  }
  onAttemptedSelected(item: AttemptedAssessmentInterface) {
    this.props.navigation.navigate('AssessmentResult', { id: item.attributes?.assessment_id })
  }

  onReFetch() {
    this.setState({
      isLoading: true,
      isError: false,
    }, () => {
      this.getAssessmentCategoriesApi()
    }

    )
  }

  convertMinutesToHoursAndMinutes(minutes: number) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let result = hours + " hour";
    if (hours !== 1) {
      result += "s";

    }
    result += remainingMinutes + " minute";
    if (remainingMinutes !== 1) {
      result += "s";
    }
    return result;
  }


  onTabClick(numInput: number) {
    this.setState({
      tabNum: numInput
    })
  }
  handleCatSelected(item: AssessmentItemInterface) {
    this.setState({ selectedId: `${item?.id}` })
    let list = this.state.assessmentList?.filter((newItem: AssessmentItemInterface) => {
      return newItem?.attributes?.assessment_type === item.attributes?.name
    })

    this.setState({
      assessmenttestList: list
    })
  }

  handleSearch = (text: string) => {
    if (text.trim() === '') {
      let first = this.state.assessmentList.filter((item: AssessmentItemInterface) => {
        {
          return item?.attributes?.assessment_type === this.state.selectedCategory
        }
      })

      this.setState({
        assessmenttestList: first
      })
    } else {
      const filtered = this.state.assessmenttestList.filter((item: AssessmentItemInterface) => {
        const name = item.attributes?.name?.toLowerCase() ?? 'Unknown';
        return name?.includes(text.toLowerCase())
      });
      this.setState({
        assessmenttestList: filtered
      })
    }
  }
  checkDarkMode=()=>{
    let mode = JSON.parse(localStorage.getItem('darkMode')!);
    if(mode)
      this.setState({
        darkTheme:mode
      })
  }
  unCompletedListAssessment(){
    let unCompletedList: AssessmentItemInterface[] = this.state.assessmenttestList.filter((item) => item.attributes?.is_completed !== true)
    return unCompletedList
  }
  uploadCSVQuizFile=(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    if(this.fileRef.current){
      this.fileRef.current.click();
    }
  }
  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState({ fileData:{
        fileName:file.name,
        filesize:file.size,
        fileType:file.type
      },uploadCSV:file });
    }
  };
  handleQuizSection= () => {
    this.setState({
      quizTabs: 0,
    },() => this.handleAddQuestions())
  }

  buildFormData = (values: Question[]) => {
    let formData = new FormData();
    const { testType, courseId } = this.props;
    const { isRequired, randomQuestion, passingGrade, reOrderIds, quizTitle } = this.state;
  
    if (testType === "quiz") {
      formData.append("quiz[title]", quizTitle);
      formData.append("quiz[course_id]", courseId.toString());
      formData.append("quiz[is_required]", isRequired.toString());
      formData.append("quiz[random_question]", randomQuestion);
      formData.append("quiz[passing_grade]", passingGrade);  
      if(this.props.quiz_id){
        formData.append("quiz[question_order]", JSON.stringify(reOrderIds));    
      }
    } else if (testType === "final_tests") {
      formData.append("final_test[title]", "Final Test");
      formData.append("final_test[course_id]", courseId.toString());
      formData.append("final_test[is_required]", isRequired.toString());
      formData.append("final_test[random_question]", randomQuestion);
      formData.append("final_test[passing_grade]", passingGrade);
      if(this.props.quiz_id){
        formData.append("final_test[question_order]", JSON.stringify(reOrderIds));
      }
    }
  
    values.forEach((question, index) => {
      const prefix = testType === "quiz" ? `quiz[questions_attributes][${index}]` : `final_test[final_test_questions_attributes][${index}]`;
      
      formData.append(`${prefix}[id]`, question.id);
      formData.append(`${prefix}[content]`, question.content);
      formData.append(`${prefix}[description]`, question.description);
      formData.append(`${prefix}[question_type]`, question.question_type);
  
      const choicePrefix = testType === "quiz" ? `${prefix}[choices_attributes]` : `${prefix}[final_test_choices_attributes]`;
      
      question.choices_attributes.forEach((choice, choiceIndex) => {
        formData.append(`${choicePrefix}[${choiceIndex}][id]`, choice.id);
        formData.append(`${choicePrefix}[${choiceIndex}][content]`, choice.content);
        formData.append(`${choicePrefix}[${choiceIndex}][is_correct]`, choice.is_correct.toString());
      });
  
      if (question.media_files) {
        question.media_files.forEach((file, mediaIndex) => {
          formData.append(`${prefix}[media_files][${mediaIndex}]`, file);
        });
      }
    });
  
    return formData;
  }
  
  pendingCallback: ((values: { title: string; content: string; optional_text: string }) => void) | null = null;

  handleCallBack = async (callbackFunction?: (values?: any) => void | Promise<void>) => {
    this.setState({isLoading:true})
    const formData = this.buildFormData(this.state.allQuestions);
    const token = await getStorageData("authToken");
  
    const apiCallConfig = {
      method2: this.props.quiz_id && this.props.quiz_id !== "" ? configJSON.putAssessementMethod : configJSON.postMethod,
      endPoint2: this.props.quiz_id && this.props.quiz_id !== "" ? `${configJSON.postQuizURL}/${this.props.testType}/${this.props.quiz_id}` : `${configJSON.postQuizURL}/${this.props.testType}`,
      body2: formData,
      headers2: {
        token
      },
    };
  
    const apiCallId = this.props.quiz_id ? "updateQuizPostAPICallId" : "addQuizPostAPICallId";
    this[apiCallId] = await this.apiCall(apiCallConfig);
    if(callbackFunction){
      this.pendingCallback = callbackFunction;
    }

  }
  
  handleQuizTabs = (num: number) => {
    this.setState({
      quizTabs:num,
      fileData: {
        fileName: '',
        filesize: 0,
        fileType: '',
      }
    })
  }
  handleAddQuestions = () => {
    const newQuestion: Question = {
      id:'',
      quiz_id:'',
      content: '',
      description: '',
      question_type: '',
      choices_attributes: [ ],
      media_files:[ ],
      media_files_urls:[ ],
    }
    this.setState(prevState => ({
      allQuestions: [...prevState.allQuestions, newQuestion],
      expandedQuestionIndex: prevState.allQuestions.length
    }))
  }
  handleChangeAccordionExpanding = (index: number) => (_event: ChangeEvent<{}>, newExpanded: boolean) => {
    this.setState({ expandedQuestionIndex: newExpanded ? index : null })
  };
  handleCopyQuestion = (index: number) => (event: { stopPropagation: () => void; }) => {
    event.stopPropagation();

    this.setState(prevState => {
      const copiedQuestion: Question = { 
        ...prevState.allQuestions[index], 
        id: "", 
        quiz_id:"",
        choices_attributes: prevState.allQuestions[index]?.choices_attributes?.map(choice => ({
          ...choice,
          id: "" 
        }))
      };
  
      return {
        allQuestions: [...prevState.allQuestions, copiedQuestion],
        expandedQuestionIndex: prevState.allQuestions.length
      };
    });
  }


  handleQuestionAddition = (event: any, index: number) => {
    let newQuestions = [...this.state.allQuestions];
    let updatedQuestion = event?.target?.value;
  
    if (newQuestions[index] && newQuestions[index].content !== updatedQuestion) {
      newQuestions[index].content = updatedQuestion;
      this.setState({ allQuestions: newQuestions });
    }
  }

  handlePassingGrades = (value: string) => {
    let numericValue = value.replace(/\D/g, '');
    
    if (numericValue !== '') {
        let num = parseInt(numericValue, 10);
        if (num > 100) {
            num = 100;
        }
        numericValue = num + '%';
    }

    this.setState({ passingGrade: numericValue });
  };
  
  handleQuestionDescAddition=(value:string, index:number)=>{
    let newQuestions = this.state.allQuestions;
    let updatedQuestion = value;
    newQuestions[index].description=updatedQuestion;
    this.setState({
      allQuestions:newQuestions
    })
  }
  handleChoiceAddition = (value: string, index: number, content_index: number) => {
  let newContent = [...this.state.allQuestions];
  let updatedContent = value;

  if (newContent[index] && newContent[index].choices_attributes) {
    let updatedChoices = [...newContent[index].choices_attributes];

    if (updatedChoices[content_index]) {
      updatedChoices[content_index].content = updatedContent;
    }

    newContent[index].choices_attributes = updatedChoices;
    
    this.setState({
      allQuestions: newContent,
    });
  }
};

  handleAnswerAddition=(event:any,index:number,content_index:number)=>{
    let newAnswers = [...this.state.allQuestions];

  if (newAnswers[index] && Array.isArray(newAnswers[index].choices_attributes)) {

    if (newAnswers[index]?.question_type === "single_choice") {
      newAnswers[index].choices_attributes.forEach(choice => {
        choice.is_correct = false;
      });
    }

   if (newAnswers[index].choices_attributes[content_index]) {
      newAnswers[index].choices_attributes[content_index].is_correct = 
        !newAnswers[index].choices_attributes[content_index].is_correct;
      this.setState({
        allQuestions: newAnswers
      });
    }
  }
  }
  handleNewOptionAddition=(index:number)=>{
    this.setState((prevState)=>{
      let updatedOptions = [...prevState.allQuestions];
      updatedOptions[index]?.choices_attributes?.push({id:'',content:"",is_correct:false});
      return {allQuestions:updatedOptions}
    })
  }
  handleQuestionTypeChange=(event:any,index:number)=>{
    let newQuestionType = this.state.allQuestions;
    newQuestionType[index].question_type=event.target.value;
    this.setState({
      allQuestions:newQuestionType
    })
   
  }
  handleUpdateQuestion = () => {
    this.setState({expandedQuestionIndex: null})
  }

  handleRemoveFile = () => {
    this.setState({
      fileData: {
        fileName: '',
        filesize: 0,
        fileType: '',
      }
    });
  };
  closeSuccessModal = () => {
    this.setState({ successModalOpen: false })
  }
  closeSuccessModal2 = () => {
    this.setState({ successModalOpen: false, showMedia:true })

  }
  openSuccess = () =>{
    this.setState({ successModalOpen: true })
  }
  setFieldValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(event.target.files || []);
    const validFiles = files.filter(file => file.type.startsWith("image/") || file.type.startsWith("video/"));
  
    this.setState(prevState => {
      const expandedIndex = prevState.expandedQuestionIndex;
  
      if (expandedIndex === null || expandedIndex === undefined) {
        return null;
      }
  
      const currentQuestion = prevState.allQuestions[expandedIndex];
  
      const existingFiles = currentQuestion.media_files || [];
      const newTotalFiles = existingFiles.length + validFiles.length;
    
      if (newTotalFiles > 4) {
        alert("You cannot select more than 4 files for this question.");
        return null; 
      }
  
      const updatedQuestions = prevState.allQuestions.map((question, index) => {
        if (index === expandedIndex) {
          return {
            ...question,
            media_files: [...existingFiles, ...validFiles],
          };
        }
        return question; 
      });      
      return { 
        ...prevState, 
        allQuestions: updatedQuestions 
      };
    });
  
    event.target.value = "";  
  };
  
  handleBrowseClick = () => {
    const fileInput = document.getElementById("uploadedMedia");
    if (fileInput) {
      fileInput.click();
    }
  };

  deleteImage = async (questionIndex:string, imageIndex:number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postQuizURL + "/" + `${this.props.testType}`+ "/" + `${questionIndex}` + "/remove_image/" + `${imageIndex}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleRemoveMediaFile = (questionIndex: number, fileIndex: number, imageIndex: any, questionId:string) => {
    if (imageIndex) {
      this.deleteImage(questionId, imageIndex);
    } else {
      this.setState(prevState => {
        const updatedQuestions = [...prevState.allQuestions];
        const currentQuestion = updatedQuestions[questionIndex];
  
        if (currentQuestion) {
          const mediaFilesCount = currentQuestion.media_files?.length || 0;
  
          if (fileIndex < mediaFilesCount) {
            currentQuestion.media_files = currentQuestion.media_files.filter((_, i) => i !== fileIndex);
          } else {
            const adjustedIndex = fileIndex - mediaFilesCount;
            currentQuestion.media_files_urls = currentQuestion.media_files_urls?.filter((_, i) => i !== adjustedIndex);
          }
        }
  
        return { allQuestions: updatedQuestions };
      });
    }
  };
  
  
  requiredQuiz = (e:any) =>{
    this.setState({isRequired:e.target.checked})
  }

  handleRandomQuestionNumber = (value: string) => {
    const num = value.replace(/\D/g, ''); 
    if (num === "" || parseInt(num, 10) <= this.state.allQuestions.length) {
      this.setState({ randomQuestion: num });
    }
  };

  handleCheckboxChange = (event: any) => {
    const passRequired = event.target.checked;
    this.setState({
      passRequired,
      passingGrade: passRequired ? this.state.passingGrade : "",
    });
  };   

  randomRequired = (event: any) => {
    const randomRequired = event.target.checked;
    this.setState({
      randomRequired,
      randomQuestion: randomRequired ? this.state.randomQuestion : "",
    });
  };  


  handleDragEnd = (result:any) => {
    const { source, destination } = result;
    if (!destination) return;
  
    const allQuestions = [...this.state.allQuestions];
    const [reorderedQuestion] = allQuestions.splice(source.index, 1);
    allQuestions.splice(destination.index, 0, reorderedQuestion);
  
    this.setState({ allQuestions });

    const reorderedData: number[] = allQuestions.map((q) => Number(q.id));

    this.setState({ reOrderIds: reorderedData });

  };

  backToDashboard = () =>{
    this.handleCallBack()
  }
  
  handleSaveAndClose = () =>{
    this.setState({isSaveAndCloseOpen:true})
  }

  handleCloseSaveAndCloseModal = () =>{
    this.setState({isSaveAndCloseOpen:false})
  }

  downloadSample = () => {
    const headers = ["question_content", "description", "question_type", "choice_content", "correct_choice"];

    const sampleData = [
      ["What is the capital of France?", "Basic Question", "single_choice", "Paris;London;Berlin;Sweden", "Paris"],
      ["What is 3 + 3?", "Addition", "single_choice", "2;3;6", "6"],
      ["What are the passing grades for Quiz1?", "Basic Question", "multiple_choice", "30;20;40", "30;40"],
      ["Which planets are gas giants?", "Astronomy", "multiple_choice", "Jupiter;Mars;Saturn;Venus", "Jupiter;Saturn"],
      ["Which of these are programming languages?", "Tech Question", "multiple_choice", "JavaScript;Python;HTML;CSS", "JavaScript;Python"],
      ["Is the Earth round?", "General Knowledge", "single_choice", "Yes;No", "Yes"],
      ["Which colors are primary colors?", "Art", "multiple_choice", "Red;Blue;Green;Yellow", "Red;Blue;Yellow"],
      ["Which are mammals?", "Biology", "multiple_choice", "Dog;Shark;Elephant;Crocodile", "Dog;Elephant"]
    ];    
  
    const csvContent =
      [headers, ...sampleData].map(row => row.join(",")).join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
  
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample_format.csv";
    document.body.appendChild(a);
    a.click();
  
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }; 
  
  handleQuizTitle = (e:React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({quizTitle:e.target.value})
  }

  // Customizable Area End
}
